import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../layouts'

import AboutSidenav from "../../partials/about-sidenav"

import '../../scss/about.scss'

import partners from "../../data/partners.yaml"


const Consortium = ({data}) => {
  console.log(partners)

  return (
    <Layout>
      <div className="about partners">

      <Helmet>
        <title>DigiWells Consortium</title>
        <meta property="og:title" content="DigiWells Consortium" />
        <meta name="description" content="Get to know the DigiWells Consortium." />
        <meta property="og:description" content="Get to know the DigiWells Consortium." />
        {
          /*
          <meta name="image" content={image} />
          <meta property="og:image" content={image} />
          */
        }
      </Helmet>

        <div className="container">
          <AboutSidenav />

          <div className="about-content">
            <h1 className="page-title">DigiWells Consortium</h1>

            <h2>Research partners</h2>
            <div className="logo-wrapper">
              {
                  partners.researchPartners &&
                  partners.researchPartners.map(function(partner) {
                    return (
                      // <a href={partner.url}>
                      <img className="logo" 
                      alt={partner.company + " logo"} 
                      src={partner.logo}/>
                      // </a>
                    )
                  })
              }
            </div>
            <h2>International research partners</h2>
            <div className="logo-wrapper">
              {
                  partners.international &&
                  partners.international.map(function(partner) {
                    return (
                      //<a href={partner.url}>
                      <img className="logo" 
                      alt={partner.company + " logo"} 
                      src={partner.logo}/>
                      //</a>
                    )
                  })
              }
            </div>
            <h2>Sponsoring partners</h2>
            <div className="logo-wrapper">
              {
                  partners.industrialPartners &&
                  partners.industrialPartners.map(function(partner) {
                    return (
                      //<a href={partner.url}>
                      <img className="logo" 
                      alt={partner.company + " logo"} 
                      src={partner.logo}/>
                      //</a>
                    )
                  })
              }
            </div>
            <h2>In-kind partners</h2>
            <div className="logo-wrapper">
              {
                  partners.industrialPartnersInKind &&
                  partners.industrialPartnersInKind.map(function(partner) {
                    return (
                      // <a href={partner.url}>
                      <img className="logo" 
                      alt={partner.company + " logo"} 
                      src={partner.logo}/>
                      // </a>
                    )
                  })
              }
            </div>

            <h2>State support</h2>
            <div className="logo-wrapper">
              {
                  partners.govermentPartners &&
                  partners.govermentPartners.map(function(partner) {
                    return (
                      // <a href={partner.url}>
                      <img className="logo" 
                      alt={partner.company + " logo"} 
                      src={partner.logo}/>
                      // </a>
                    )
                  })
              }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Consortium
